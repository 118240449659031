import { useState } from 'react'
import { useSelector } from 'react-redux'

import { BjsRenderer, SetCamera } from '@orangelv/bjs-renderer'

import { Nodes, ScreenSize } from '../../common/typings'
import RawlingsRenderer from '../../../_rawlings/client/components/Renderer'
import getMenuFromMeshId from '../../renderer/getMenuFromMeshId'
import getRendererConfig from '../../renderer/getRendererConfig'
import { openMenu } from '../../../../platform/client/common/actions'
import controlTree from '../controlTree'
import { useAppDispatch } from '../../../../platform/client/configureStore'
import onGeneratePreviews from '../../renderer/onGeneratePreviews'

type Props = {
  onSetCamera: (setCamera: SetCamera) => void
  setCameraRef: { current?: SetCamera }
  defaultCamera: string
  layoutMode: ScreenSize
}

const Renderer3d = (props: Props) => {
  const [isLoading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const nodes: Nodes = useSelector(controlTree.getNodes)

  return (
    <div>
      <RawlingsRenderer isLoading={isLoading}>
        <BjsRenderer
          config={getRendererConfig({
            nodes,
            defaultCamera: props.defaultCamera,
            layoutMode: props.layoutMode,
          })}
          onLoading={(isLoading) => {
            setLoading(isLoading)
          }}
          onSetCamera={(setCamera) => {
            props.onSetCamera(setCamera)
          }}
          onMeshPicked={(meshId) => {
            const menu = getMenuFromMeshId(meshId, nodes)

            if (!menu) {
              return
            }

            dispatch(openMenu(controlTree, menu))
          }}
          onGeneratePreviews={onGeneratePreviews}
        />
      </RawlingsRenderer>
    </div>
  )
}

export default Renderer3d
