// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { LogoRow } from '../typings';

const data = [
  {
    "id": "0450R-24B",
    "name": t('rawlingsGloves:sheets.logos.0450R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0445R-24B",
    "name": t('rawlingsGloves:sheets.logos.0445R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0444R-24B",
    "name": t('rawlingsGloves:sheets.logos.0444R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0508R-24B",
    "name": t('rawlingsGloves:sheets.logos.0508R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0448R-24B",
    "name": t('rawlingsGloves:sheets.logos.0448R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0451R-24B",
    "name": t('rawlingsGloves:sheets.logos.0451R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0507R-24B",
    "name": t('rawlingsGloves:sheets.logos.0507R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0442R-24B",
    "name": t('rawlingsGloves:sheets.logos.0442R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0449R-24B",
    "name": t('rawlingsGloves:sheets.logos.0449R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0440R-24B",
    "name": t('rawlingsGloves:sheets.logos.0440R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0443R-24B",
    "name": t('rawlingsGloves:sheets.logos.0443R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0509R-24B",
    "name": t('rawlingsGloves:sheets.logos.0509R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0447R-24B",
    "name": t('rawlingsGloves:sheets.logos.0447R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0441R-24B",
    "name": t('rawlingsGloves:sheets.logos.0441R-24B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "0510R-20B",
    "name": t('rawlingsGloves:sheets.logos.0510R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0873R-23B",
    "name": t('rawlingsGloves:sheets.logos.0873R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0223R-20B",
    "name": t('rawlingsGloves:sheets.logos.0223R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0905R-23B",
    "name": t('rawlingsGloves:sheets.logos.0905R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0870R-23B",
    "name": t('rawlingsGloves:sheets.logos.0870R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0511R-20B",
    "name": t('rawlingsGloves:sheets.logos.0511R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0883R-23B",
    "name": t('rawlingsGloves:sheets.logos.0883R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0380R-20B",
    "name": t('rawlingsGloves:sheets.logos.0380R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0906R-23B",
    "name": t('rawlingsGloves:sheets.logos.0906R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0909R-23B",
    "name": t('rawlingsGloves:sheets.logos.0909R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0451R-20B",
    "name": t('rawlingsGloves:sheets.logos.0451R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0902R-23B",
    "name": t('rawlingsGloves:sheets.logos.0902R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0884R-23B",
    "name": t('rawlingsGloves:sheets.logos.0884R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0378R-20B",
    "name": t('rawlingsGloves:sheets.logos.0378R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0866R-23B",
    "name": t('rawlingsGloves:sheets.logos.0866R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0452R-20B",
    "name": t('rawlingsGloves:sheets.logos.0452R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0893R-23B",
    "name": t('rawlingsGloves:sheets.logos.0893R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0885R-23B",
    "name": t('rawlingsGloves:sheets.logos.0885R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0057R-22B",
    "name": t('rawlingsGloves:sheets.logos.0057R-22B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0869R-23B",
    "name": t('rawlingsGloves:sheets.logos.0869R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0864R-23B",
    "name": t('rawlingsGloves:sheets.logos.0864R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0453R-22B",
    "name": t('rawlingsGloves:sheets.logos.0453R-22B.name'),
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0224R-20B",
    "name": t('rawlingsGloves:sheets.logos.0224R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0908R-23B",
    "name": t('rawlingsGloves:sheets.logos.0908R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0892R-23B",
    "name": t('rawlingsGloves:sheets.logos.0892R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0408R-20B",
    "name": t('rawlingsGloves:sheets.logos.0408R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0898R-23B",
    "name": t('rawlingsGloves:sheets.logos.0898R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0895R-23B",
    "name": t('rawlingsGloves:sheets.logos.0895R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0454R-20B",
    "name": t('rawlingsGloves:sheets.logos.0454R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0868R-23B",
    "name": t('rawlingsGloves:sheets.logos.0868R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0455R-20B",
    "name": t('rawlingsGloves:sheets.logos.0455R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0863R-23B",
    "name": t('rawlingsGloves:sheets.logos.0863R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0871R-23B",
    "name": t('rawlingsGloves:sheets.logos.0871R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0512R-20B",
    "name": t('rawlingsGloves:sheets.logos.0512R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0899R-23B",
    "name": t('rawlingsGloves:sheets.logos.0899R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0457R-20B",
    "name": t('rawlingsGloves:sheets.logos.0457R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0891R-23B",
    "name": t('rawlingsGloves:sheets.logos.0891R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0910R-23B",
    "name": t('rawlingsGloves:sheets.logos.0910R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0409R-20B",
    "name": t('rawlingsGloves:sheets.logos.0409R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0491R-20B",
    "name": t('rawlingsGloves:sheets.logos.0491R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0897R-23B",
    "name": t('rawlingsGloves:sheets.logos.0897R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0379R-20B",
    "name": t('rawlingsGloves:sheets.logos.0379R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0459R-20B",
    "name": t('rawlingsGloves:sheets.logos.0459R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0894R-23B",
    "name": t('rawlingsGloves:sheets.logos.0894R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0890R-23B",
    "name": t('rawlingsGloves:sheets.logos.0890R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0460R-20B",
    "name": t('rawlingsGloves:sheets.logos.0460R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0901R-23B",
    "name": t('rawlingsGloves:sheets.logos.0901R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0860R-23B",
    "name": t('rawlingsGloves:sheets.logos.0860R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0461R-20B",
    "name": t('rawlingsGloves:sheets.logos.0461R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0889R-23B",
    "name": t('rawlingsGloves:sheets.logos.0889R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0862R-23B",
    "name": t('rawlingsGloves:sheets.logos.0862R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0463R-20B",
    "name": t('rawlingsGloves:sheets.logos.0463R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0861R-23B",
    "name": t('rawlingsGloves:sheets.logos.0861R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0880R-23B",
    "name": t('rawlingsGloves:sheets.logos.0880R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0464R-20B",
    "name": t('rawlingsGloves:sheets.logos.0464R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0867R-23B",
    "name": t('rawlingsGloves:sheets.logos.0867R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0907R-23B",
    "name": t('rawlingsGloves:sheets.logos.0907R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0465R-20B",
    "name": t('rawlingsGloves:sheets.logos.0465R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0874R-23B",
    "name": t('rawlingsGloves:sheets.logos.0874R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0887R-23B",
    "name": t('rawlingsGloves:sheets.logos.0887R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0466R-20B",
    "name": t('rawlingsGloves:sheets.logos.0466R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0857R-23B",
    "name": t('rawlingsGloves:sheets.logos.0857R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0492R-20B",
    "name": t('rawlingsGloves:sheets.logos.0492R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0468R-20B",
    "name": t('rawlingsGloves:sheets.logos.0468R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0900R-23B",
    "name": t('rawlingsGloves:sheets.logos.0900R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0881R-23B",
    "name": t('rawlingsGloves:sheets.logos.0881R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0407R-20B",
    "name": t('rawlingsGloves:sheets.logos.0407R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0493R-20B",
    "name": t('rawlingsGloves:sheets.logos.0493R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0872R-23B",
    "name": t('rawlingsGloves:sheets.logos.0872R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0471R-20B",
    "name": t('rawlingsGloves:sheets.logos.0471R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0472R-20B",
    "name": t('rawlingsGloves:sheets.logos.0472R-20B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0904R-23B",
    "name": t('rawlingsGloves:sheets.logos.0904R-23B.name'),
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "0056R-22B",
    "name": t('rawlingsGloves:sheets.logos.0056R-22B.name'),
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "categoryId": "standard"
  },
  {
    "id": "1227R",
    "name": t('rawlingsGloves:sheets.logos.1227R.name'),
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "1695R",
    "name": t('rawlingsGloves:sheets.logos.1695R.name'),
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  },
  {
    "id": "1694R",
    "name": t('rawlingsGloves:sheets.logos.1694R.name'),
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "categoryId": "mlb"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LogoRow[]>> = data;

export type Logo = typeof typed[number];

export const LOGO_INDEX_KEY = "id";
export type LogoIndexKey = "id";
export type LogoId = Logo["id"];

let i = 0;
export const LOGO_DICT = {
  "0450R-24B": typed[i++],
  "0445R-24B": typed[i++],
  "0444R-24B": typed[i++],
  "0508R-24B": typed[i++],
  "0448R-24B": typed[i++],
  "0451R-24B": typed[i++],
  "0507R-24B": typed[i++],
  "0442R-24B": typed[i++],
  "0449R-24B": typed[i++],
  "0440R-24B": typed[i++],
  "0443R-24B": typed[i++],
  "0509R-24B": typed[i++],
  "0447R-24B": typed[i++],
  "0441R-24B": typed[i++],
  "0510R-20B": typed[i++],
  "0873R-23B": typed[i++],
  "0223R-20B": typed[i++],
  "0905R-23B": typed[i++],
  "0870R-23B": typed[i++],
  "0511R-20B": typed[i++],
  "0883R-23B": typed[i++],
  "0380R-20B": typed[i++],
  "0906R-23B": typed[i++],
  "0909R-23B": typed[i++],
  "0451R-20B": typed[i++],
  "0902R-23B": typed[i++],
  "0884R-23B": typed[i++],
  "0378R-20B": typed[i++],
  "0866R-23B": typed[i++],
  "0452R-20B": typed[i++],
  "0893R-23B": typed[i++],
  "0885R-23B": typed[i++],
  "0057R-22B": typed[i++],
  "0869R-23B": typed[i++],
  "0864R-23B": typed[i++],
  "0453R-22B": typed[i++],
  "0224R-20B": typed[i++],
  "0908R-23B": typed[i++],
  "0892R-23B": typed[i++],
  "0408R-20B": typed[i++],
  "0898R-23B": typed[i++],
  "0895R-23B": typed[i++],
  "0454R-20B": typed[i++],
  "0868R-23B": typed[i++],
  "0455R-20B": typed[i++],
  "0863R-23B": typed[i++],
  "0871R-23B": typed[i++],
  "0512R-20B": typed[i++],
  "0899R-23B": typed[i++],
  "0457R-20B": typed[i++],
  "0891R-23B": typed[i++],
  "0910R-23B": typed[i++],
  "0409R-20B": typed[i++],
  "0491R-20B": typed[i++],
  "0897R-23B": typed[i++],
  "0379R-20B": typed[i++],
  "0459R-20B": typed[i++],
  "0894R-23B": typed[i++],
  "0890R-23B": typed[i++],
  "0460R-20B": typed[i++],
  "0901R-23B": typed[i++],
  "0860R-23B": typed[i++],
  "0461R-20B": typed[i++],
  "0889R-23B": typed[i++],
  "0862R-23B": typed[i++],
  "0463R-20B": typed[i++],
  "0861R-23B": typed[i++],
  "0880R-23B": typed[i++],
  "0464R-20B": typed[i++],
  "0867R-23B": typed[i++],
  "0907R-23B": typed[i++],
  "0465R-20B": typed[i++],
  "0874R-23B": typed[i++],
  "0887R-23B": typed[i++],
  "0466R-20B": typed[i++],
  "0857R-23B": typed[i++],
  "0492R-20B": typed[i++],
  "0468R-20B": typed[i++],
  "0900R-23B": typed[i++],
  "0881R-23B": typed[i++],
  "0407R-20B": typed[i++],
  "0493R-20B": typed[i++],
  "0872R-23B": typed[i++],
  "0471R-20B": typed[i++],
  "0472R-20B": typed[i++],
  "0904R-23B": typed[i++],
  "0056R-22B": typed[i++],
  "1227R": typed[i++],
  "1695R": typed[i++],
  "1694R": typed[i++]
} as const;

export { typed as LOGOS };